'use client';
import React, { ForwardedRef, forwardRef } from 'react';

import Image from '~/components/atoms/Image/Image';
import Svg from '~/components/atoms/Svg/Svg';
import getSvgDimensions from '~/utils/getSvgDimensions';

import { GRAPHIC_IMAGE_MULTIPLIER, GraphicProps } from './Graphic.types';

/**
 * Graphic molecule
 * @description used to display icons, logos, etc. It either displays a SVG or an image.
 * @param alt (Optional) Alt text
 * @param className CSS Classes
 * @param svg SVG props
 * @param image Image props
 * @param isInView Image props

 */
const Graphic = (
  { name, className, source, isInView, disableAutoSize = false }: GraphicProps,
  ref: ForwardedRef<HTMLElement> | null,
) => {
  if (!source?.type) {
    return null;
  }

  let graphicMedia;
  let graphicDimensions;

  if (source.type === 'svg') {
    graphicDimensions = getSvgDimensions(source.markupString);

    graphicMedia = (
      <Svg ref={ref as ForwardedRef<SVGElement>} source={source} name={name} />
    );
  }

  if (source.type === 'image') {
    source.alt = name || '';

    graphicDimensions = {
      width: source.asset.width / GRAPHIC_IMAGE_MULTIPLIER,
      height: source.asset.height / GRAPHIC_IMAGE_MULTIPLIER,
    };

    graphicMedia = (
      <Image
        contain={true}
        ref={ref as ForwardedRef<HTMLElement>}
        source={source}
        isDisplayed={isInView}
      />
    );
  }

  if (graphicMedia && graphicDimensions) {
    if (!graphicDimensions.width || !graphicDimensions.height) {
      console.error('Invalid Graphic Dimensions', graphicDimensions, source);
    }

    return (
      <div
        className={className}
        style={{
          width: disableAutoSize
            ? `100%`
            : `calc(${
                Math.round(graphicDimensions.width) / 10
              }rem * var(--graphic-scale, 1))`,
          height: disableAutoSize
            ? `100%`
            : `calc(${
                Math.round(graphicDimensions.height) / 10
              }rem * var(--graphic-scale, 1))`,
        }}
      >
        {graphicMedia}
      </div>
    );
  }

  return null;
};

export default forwardRef(Graphic);
